@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body, html {
    @apply font-sans;
    scroll-behavior: smooth;
  }

  h1, h2, h3 {
    @apply text-strongBlack;
    @apply font-bold;
  }

  h1 {
    @apply text-4xl;
    @apply leading-tight;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  p, span, input, label {
    @apply text-softBlack;
  }

  label {
    @apply font-semibold;
  }

  input, button,
  input:focus, button:focus {
    outline: none;
  }
}

@layer components {
  #root {
    @apply min-h-screen w-full;
    @apply grid;
    grid-template-rows: auto 1fr auto;
  }
  .btn {
    @apply py-2;
    @apply px-3;
    @apply transition;
    @apply duration-300;
    @apply rounded-sm;
    @apply text-strongBlack;
  }

  [class^="btn"]:disabled {
    cursor: not-allowed;
  }

  .btn-primary {
    @apply btn;
    @apply text-white;
    @apply font-bold;
    @apply bg-primary;
  }
  .btn-primary:disabled {
    @apply bg-disabled;
  }

  .btn-outline {
    @apply btn;
    padding-top: 7px;
    padding-bottom: 7px;
    @apply font-normal;
    @apply bg-transparent;
    @apply border border-strongBlack;
  }

  .btn-link {
    @apply btn;
  }

  .section {
    @apply px-6 mx-auto max-w-content w-full;
  }

  .hero {
    height: calc(100vh - theme('height.28'));
  }

  .title-underline {
    @apply mt-2;
    @apply h-1;
    @apply bg-secondary;
    @apply rounded-full;
  }

  .gdShadow {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  }

  .gdShadowedBox {
    @apply gdShadow;
    @apply p-4 gdShadow rounded-md;
  }

  .accordion__button {
    outline: none;
  }
  .accordion__button[aria-expanded=true] .accordion-caret {
    transform: rotate(90deg);
  }
  .accordion__button[aria-expanded=true] span {
    @apply font-bold;
  }

  .gdModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    @apply w-full;
    @apply h-full;
    @apply flex items-center justify-center;
    background-color: rgba(0,0,0,0.7);
  }
  .gdModal {
    @apply bg-white;
    @apply rounded;
    @apply overflow-hidden;
    padding: 0;
    max-width: 650px;
    width: 90%;
    outline: none;
    margin: auto;
  }
  .gdModal li{
    margin-left: 20px;
    list-style: inside;
  }

  .privacy {
    padding: 2rem 0;
  }
  .privacy h3{
    margin-top: 2rem;
    margin-botom: 0.5rem;
  }
  .privacy li{
    margin-left: 20px;
    list-style: inside;
    @apply text-softBlack;
  }

  .spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    @apply bg-primary;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
}

@layer utilities {
  @variants hover {
    .btn-primary:not(:disabled) {
      @apply hover:bg-primarySoft;
    }

    .btn-outline:not(:disabled) {
      @apply bg-transparent;
      @apply hover:bg-transparent;
      @apply hover:text-primary;
      @apply hover:border-primary;
    }

    .btn-link:not(:disabled) {
      @apply hover:bg-gray-100;
      @apply hover:text-primary;
    }
  }
}

.bouncing {
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 1rem, 0);
  }
}



@screen md {
  .hero-content {
     max-height: calc(100vh - 170px);
  }
}

#how-it-works {
  padding-top: 56px;
  padding-bottom: 56px;
}
